@font-face {
    font-family: 'hkbn-icon';
    src: url('./font/hkbn-icon.woff') format('woff')
}

[class^="icon-"], [class*=" icon-"]{
	font-family: 'hkbn-icon' !important;
    speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
}

[class^="icon-"] span{
    font-family: 'hkbn-icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
    content: "\E964";
}
.icon-youtube:before {
    content: "\E96C";
}
.icon-linkedin:before {
    content: "\E965";
}
.icon-my-acc:before {
    content: "\E950";
}
.icon-logout:before {
    content: "\E94B"
}
.icon-single-home-tel:before {
    content: "\e968"
}
.icon-two-tone-broadband .path1:before{
    content: '\e93a';
}
.icon-two-tone-broadband .path2:before{
    content: '\e93b';
    margin-left: -1em;
    color: #00508B;
}
.icon-two-tone-broadband .path3:before{
    content: '\e93c';
    margin-left: -1em;
    color: #f26f21;
}
.icon-two-tone-broadband .path4:before{
    content: '\e93d';
    margin-left: -1em;
    color: #f26f21;
}
.icon-two-tone-broadband .path5:before{
    content: '\e93e';
    margin-left: -1em;
    color: #f26f21;
}
.icon-two-tone-broadband .path6:before{
    content: '\e93f';
    margin-left: -1em;
    color: #f26f21;
}
.icon-two-tone-broadband .path7:before{
    content: '\e940';
    margin-left: -1em;
    color: #f26f21;
}
.icon-two-tone-broadband .path8:before{
    content: '\e941';
    margin-left: -1em;
    color: #f26f21;
}

.icon-two-tone-handset .path1:before{
    content: "\e923";
    color: rgb(0, 80, 140);
} 
.icon-two-tone-handset .path2:before{
    content: "\e924";
    margin-left: -1em;
    color: rgb(0, 80, 140);
}
.icon-two-tone-handset .path3:before{
    content: "\e925";
    margin-left: -1em;
    color: rgb(0, 80, 140);
}
.icon-two-tone-handset .path4:before{
    content: "\e926";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}
    
.icon-two-tone-entertrainment .path1:before{
    content: "\e92b";
    color: rgb(0, 80, 140);
}
.icon-two-tone-entertrainment .path2:before{
    content: "\e92c";
    margin-left: -1em;
    color: rgb(0, 80, 140);
}
.icon-two-tone-entertrainment .path3:before{
    content: "\e92d";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}

.icon-new-save_money .path1:before{
    color: rgb(0, 80, 140);
    content: "\e9ad";
}  
.icon-new-save_money .path2:before{
    content: "\e9ae";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}

.icon-support .path1:before{
    color: rgb(0, 80, 140);
    content: "\e991"
}   

.icon-support .path2:before{
    content: "\e992";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}
    
.icon-new-package .path1:before{
    color: rgb(0, 80, 140);
    content: "\e9ab";
}

.icon-new-package .path2:before{
    content: "\e9ac";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}
    
.icon-new-hometel .path1:before{
    color: rgb(0, 80, 140);
    content: "\e9b7";
}

.icon-new-hometel .path2:before{
    content: "\e9b8";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}

.icon-travel-flight .path1:before{
    color: rgb(0, 80, 140);
    content: "\e9c1";
}

.icon-travel-flight .path2:before{
    content: "\e9c2";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}

.icon-gift .path1:before{
    color: rgb(0, 80, 140);
    content: "\e9a7";
}

.icon-gift .path2:before{
    content: "\e9a8";
    margin-left: -1em;
    color: rgb(242, 111, 33);
}