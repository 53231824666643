.CommonLibraryNavBar .logo {
    width: 150px;
    max-width: 65%;
    height: 70px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    background-image: url('../img/navbar/logo.svg');
    cursor: pointer;
}
.orange {
    color: #f68a33 !important
}
.blue {
    color: #00508B !important;
}
.gray {
    color: gray;
}
.hkbn-gray{
    color: #808080 !important;
}
.hkbn-blue {
    color: #003d69 !important;
}
.hkbn-white {
    color: white !important;
}
.navbar {
    position: static;
    width: 100%;
    background-color: white;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 0;
    z-index: 999 !important;
    font-family: "Roboto", "Microsoft JhengHei", "Roboto Condensed Number", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16.5px;
}
.main-navbar{
    width:1200px;
    box-shadow: none;
    margin: 0 auto;
}
.navbar .main-nav {
    color: #00508B !important;
    /* border-right: 1px solid #e1e1e1; */
    padding: 0 1rem;
    font-weight: 700;
}
.navBar .navbar-nav-sec {
    border: none;
}
.navbar-light {
    padding: 12.5px 50px 12.5px 50px !important; 
}
.navbar .main-nav .nav-item a, .navbar .main-nav .nav-item div {
    color: #00508B ;
    cursor: pointer;
}
.nav-search{
    position: relative;
}
.nav-search-icon{
    position: absolute;
    font-size: 22px;
    cursor: pointer;
    color: #00508B;
    bottom: 15%;
    right: 5%;
}
.nav-input{
    width: 20px;
    border-style: hidden;
    transition: all .5s;
}
.nav-input-expand{
    width: 150px;
    border-style: inset;
    transition: all .5s;
    padding-right:25px;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
.navbar .main-nav .nav-link, .isNotActive {
    box-shadow: inset 0 -3px 0 0 transparent;
    color: inherit;
}
.navbar .main-nav .nav-link:hover, .isActive {
    box-shadow: inset 0 -3px 0 0 #f68a33;
    color: inherit;
}
.navbar .main-nav .nav-no-hover {
    box-shadow: inset 0 -3px 0 0 transparent !important;
}
.dark-color{
    box-shadow: none;
    background-color: transparent;
}
.navbar .sub-nav {
    color: #808080;
    font-size: 14px;
    padding: 0 0.5rem;
    cursor: pointer;
}
.navbar .sub-nav .nav-item a {
    color: #808080;
}
.navbar .sub-nav .nav-item.active a {
    color: #666666;
    font-weight: 700;
}
.navbar .main-nav-icon {
    position: relative;
    padding-left: 10px;
    padding-top: 7px;
    font-size: 22px;
    width: 70px;
    cursor: pointer;
    color: #00508B;
}

.navbar .main-nav-icon .button-word {
    font-size: 14px;
    font-weight: bold;
}
.navbar .main-nav-icon .nav-icon-text {
    position: absolute;
    top: 9px;
    left: 35px;
    color: #00508B;
}
.navbar-sec{
    position: absolute !important;
	overflow: hidden;
	z-index: 10;
	transition: max-height 1s;
    opacity: 1;
    background-color: #003D6A;
    height: fit-content;
    max-height: 0;
    padding: 0px 0px 0px 0px !important;
}
.navbar-sec-full-height{
    transition: max-height 1s;
    max-height: 1000px;
    /* padding: 12.5px 50px 12.5px 50px !important;  */
}
.navbar-sec-wrapper {
    background-color: #003D6A;
    width: 85%;
    height: auto;
    padding-left: unset !important;
    padding-right: unset !important;
    justify-content: flex-end;
    transition: all 0.1s;
}
.navbar-sec-wrapperSM {
    background-color: #003D6A;
    width: calc(100% - (50% - 600px));
    height: auto;
    padding-left: unset !important;
    padding-right: unset !important;
    position: absolute;
    top: 0;
    justify-content: flex-end;
}
.dark-color .navbar-sec-wrapper {
    background-color: #002f50 !important;
}
.navbar-nav-sec{
    border: none !important;
}
.navbar-sec-title{
    color: #00508B ;
    font-weight: bold;
}
.dark-color .navbar-sec-title{
    color: white;
}
.dark-color .navbar-nav-sec a{
    color: white;
}
/* .dark-color .navbar-nav-sec  .nav-item a, .dark-color .navbar-nav-sec  .nav-item div  {
    color: white !important;
} */
.navbar-nav-sec-img{
    object-fit: cover;
    height: auto;
    width: auto;
    max-height: 230px;
}
.mMeun{
    position: absolute;
    width: 100%;
    left: 0px;   
    z-index: 9999;
}
.mobile-menu{
    width: 100%;
    height: calc(100vh - 85px);
    overflow-y: scroll;
    background-color: white;
}
.mobile-menu .main-page .item {
    background-color: #f4f4f4;
    color: #00508B;
    border-bottom: 1px solid #D9E3E8;
    height: 57px;
    cursor: pointer;
}
.mobile-menu .main-page .item .title h3 {
    font-weight: bold;
    font-size: 20px;
}
.mobile-menu .main-page .itemLV2 {
    background-color: #ffffff;
    color: #00508B;
    border-bottom: 1px solid #D9E3E8;
    height: 57px;
    cursor: pointer;
}
.mobile-menu .main-page .itemLV2 .title h3 {
    font-weight: bold;
}
.mobile-menu .subIsActive, .mobile-menu .isActive{
    border-left: solid 5px #f68a33;
    box-shadow: none;
}
.mobile-menu .subIsActiveLV1, .mobile-menu .isActive {
    border-left: solid 10px #f68a33;
    box-shadow: none;
}
.mobile-menu .subIsActiveLV2, .mobile-menu .isActive {
    border-left: solid 10px #00508B;
    box-shadow: none;
}
.mobile-menu .subIsNotActive, .mobile-menu .isNotActive{
    border-left: solid 5px transparent;
    box-shadow: none;
}
.mobile-menu .sub-nav .item a {
    padding-left: 45px;
    color: #00508B;
    font-size: 18px;
}
.mobile-menu .sub-nav .itemLV2 a {
    padding-left: 55px;
    color: #00508B;
    font-size: 18px;
}
.mobile-menu .sub-page {
    margin-bottom: 100px;
}
.mobile-menu .sub-page a {
    color: #666666;
}
.dark-color .icon .path1:before{
    color: white !important;
} 
.dark-color .CommonLibraryNavBar{
    position: absolute;
    z-index: 999;
    width: 100%;
}
.sce-nav-ul-row{
    margin-left: 0 !important;
}
.sce-nav-ul-list{
    list-style-type: none !important;
    padding-left: 20px !important;
    padding-right: 0px !important;
    flex-direction: column !important;
    /* border-left: 1px solid #f26f21 !important; */
}
.sec-nav-list{
    padding: 45px 0px 45px 0px;
    transition: width 0.5s;
}
.no-width{
    width:0px !important;
}
.sec-nav-transition{
    transition: width 0.5s;
}
.sec-nav-title-business-type{
    padding: 0px 0px 0px 40px;
    color: white !important;
}
.navbar-sec-small {
    background-color: #003D6A;
    width: auto;
    height: 100%;
}
.nav-highlights{
    margin-left: 510px;
}
.nav-insights{
    margin-left: 700px;
}
.nav-why-hkbn{
    margin-left: 775px;
}
.nav-support{
    margin-left: 900px;
}
.navbar-sec-lv1 {
    background-color: #003D6A;
    width: 280px;
    height: 100%;
}
.navbar-sec-lv2 {
    background-color: #f26f21;
    width: 300px;
    height: auto;
    overflow: hidden;
}
.navbar-sec-lv3 {
    background-color: #ffffff;
    /*width: calc(100% - 721.922px);*/
    height: auto;
    overflow: hidden;
}
.sec-nav-title-lv2{
    transition: all 0.1s;
    padding: 0px 0px 0px 40px;
    color:#003D6A !important;
}
.sec-nav-title-lv3{
    padding: 0px 0px 0px 40px;
    color: #f26f21 !important;
    font-weight: 700;
}
.navbar-sec-lv1 .sec-nav-ul .nav-item .sce-bar-item-active {
    background-color: #f26f21;
}
.navbar-sec-lv2 .sec-nav-ul .nav-item .sce-bar-item-active {
    background-color: #ffffff;
    color: #f26f21 !important;
}
.sec-nav-ul .nav-item .sec-nav-item{
    color: white !important;
    padding: 4px 0px 4px 20px !important;
    font-size: 1rem;
}
.sec-nav-ul-lv3{
    /* max-width: fit-content !important; */
    max-width: 300px !important;
}
.sec-nav-ul-lv3 .nav-item .sec-nav-item{
    padding: 4px 0px 4px 20px !important;
    font-size: 14px;
}
.navIcon {
    font-size: 25px
}
.toolbar {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px auto;
    padding: 5px 0px;
	position: absolute;  
}
.navArrow-active{
    color: #F26F21 !important;
}
.navArrow{
    color: white;
    float: right;
    margin-right: 20%;
}
.navbar-collapse{
    flex-grow: 0.9 !important;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus{
    color: #F26F21 !important
}


@media only screen and (max-width: 1200px) {
    .dark-color{
        position: inherit;
        background-color: white;
    }
    .navbar{
        padding: 0.25em 0;
		/*height: auto;*/
        box-shadow: none;
    }
    .navbar .navbar-toggler span {
        display: block;
        height: 2px;
        background-color: #00508B;
        width: 25px;
    }
    .navbar .navbar-toggler .m-1:nth-child(1) {
        transform: translateY(0px) rotate(0deg);
    }
    .navbar .navbar-toggler .m-1:nth-child(2) {
        display: block;
    }
    .navbar .navbar-toggler .m-1:nth-child(3) {
        transform: translateY(0px) rotate(0deg);
    }
    .navbar.show-mobile-menu .navbar-toggler .m-1:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
    }
    .navbar.show-mobile-menu .navbar-toggler .m-1:nth-child(2) {
        display: none;
    }
    .navbar.show-mobile-menu .navbar-toggler .m-1:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
    }
    .navbar.show-mobile-menu .navbar-toggler .m-1 {
        transition: all 0.1s cubic-bezier(0.4, 0, 1, 1) 0ms;
    }
    .navbar-light {
        padding: 0px !important;
    }
    .fixedTop{
        position: fixed;
        z-index: 999;
        width: 100%;
    }
    .main-navbar{
        width:auto;
    }
}



@media only screen and (max-width: 990px) {
    .navbar{
        padding: 0.25em 0;
		height: auto;
        box-shadow: none;
    }
    .mobile-menu .sub-page a {
        color: #00508B;
        outline: none;
        text-decoration: none;
    }
    .mobile-menu .langChange {
        color: #00508B;
    }
    .main-navbar{
        width:auto;
    }
}