.cn * {
    font-family: "Roboto", "Microsoft JhengHei", "Roboto Condensed Number", sans-serif;
}
.footer-background{
    background-color: #003D6A;
    display: flex;
    justify-content: center;
}
.footer-container{
    width: 1200px;
}
.footer{
    background-color: #003D6A;
    color: white;
    padding: 40px 50px 0 50px;
    font-size: 14px;
}
.footer ul li .main-page-link {
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
}
.footer.additional {
    font-size: 14px;
    color: #668BA6;
    padding-bottom: 40px;
}
.footer ul {
    display: block;
    list-style: none;
}
.footer .icon-wrapper {
    border: 1px white solid;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: block;
    font-size: 18px;
    position: relative;
    cursor: pointer;
}
.footer .icon, .footer .fa{
    color: white;
    font-size:18px !important;
}
.footer ul a, .footer ul a:hover, .footer ul a:active, .footer ul a:visited {
    color: white !important;
}
.footer .otherLink {
    border-top: none;
    border-left: solid 2px rgba(255, 255, 255, 0.6);
}
.footer .otherLink ul {
    padding-left: 0;
}
.footer .otherLink li {
    white-space: nowrap;
    line-height: 50px;
    font-size: 16px;
    font-weight: 700;
}
.footerAdditionalLinkContainer{
    min-height: 42px;
}
.footerAdditionalLink{
    color: #668BA6;
    outline: none;
}
.footerAdditionalLink:hover{
    color: #668BA6;
    outline: none;
}
#hkbn_app .footerAdditionalLink{
    color: #668BA6;
    outline: none;
}
#hkbn_app .footerAdditionalLink:hover{
    color: #668BA6;
    outline: none;
}
.footer.additional .links a::after {
    content: '|';
    display: inline-block;
    margin-left: 1em;
    margin-right: 1em;
}
.footer.additional .links a:last-child::after {
    content: '';
}

/* a{
    text-decoration: none !important;
} */

@media only screen and (max-width: 1200px) {
    .footer{
        padding: 25px 0 0 0
    }
    .footer .otherLink {
        border-left: none;
        border-top: solid 2px rgba(255, 255, 255, 0.6);
        padding: 1rem 0;
    }
    .footer .otherLink li::after {
        content: '|';
        display: inline-block;
        margin-right: 1em;
        margin-left: 1em;
    }

}